export const theme = {
  colours: {
    green: '#00946e',
    lightGreen: '#89a79c',
    white: '#fcefdd',
    orange: 'rgb( 246, 139, 36 )',
    dullOrange: 'rgb(250, 162, 27)',
    pink: '#e37870',
    orangePink: 'rgb( 249, 154, 115 )',
    darkOrange: 'rgb( 250, 162, 27 )',
    lightPink: 'rgb( 255, 221, 232 )',
    darkCream: '#f8ddb2',
    // Accessibility and forms
    focus: '#4fcd90',
    error: '#d0021b',
  },
  breakpoints: {
    sm: 'min-width: 576px',
    md: 'min-width: 768px',
    lg: 'min-width: 992px',
    xl: 'min-width: 1200px',
  },
  gutter: '0.6rem',
  halfGutter: '0.3rem',
  typography: {
    base: 2.2,
    letterSpacing: '0.2rem',
    scale: {
      sm: 1.2,
      md: 1.2,
      lg: 1.2,
      xl: 1.2,
    }
  },
  typeStyles: ( exp ) => {
    return `font-size: ${theme.typography.base * Math.pow( theme.typography.scale.sm, exp )}rem;
    @media ( ${theme.breakpoints.sm} ) {
      font-size: ${theme.typography.base * Math.pow( theme.typography.scale.md, exp )}rem;
    }
    @media ( ${theme.breakpoints.md} ) {
      font-size: ${theme.typography.base * Math.pow( theme.typography.scale.lg, exp )}rem;
    }
    @media ( ${theme.breakpoints.lg} ) {
      font-size: ${theme.typography.base * Math.pow( theme.typography.scale.xl, exp )}rem;
    }`
  },
};
