import { createGlobalStyle } from 'styled-components';

import AvenirNextLTProDemiWoff2 from './../../fonts/AvenirNextLTPro-Demi/font.woff2';
import AvenirNextLTProDemiWoff from './../../fonts/AvenirNextLTPro-Demi/font.woff';
import AvenirNextLTProDemiItWoff2 from './../../fonts/AvenirNextLTPro-DemiIt/font.woff2';
import AvenirNextLTProDemiItWoff from './../../fonts/AvenirNextLTPro-DemiIt/font.woff';

import AvenirNextLTProHeavyWoff2 from './../../fonts/AvenirNextLTPro-Heavy/font.woff2';
import AvenirNextLTProHeavyWoff from './../../fonts/AvenirNextLTPro-Heavy/font.woff';
import AvenirNextLTProHeavyItWoff2 from './../../fonts/AvenirNextLTPro-HeavyIt/font.woff2';
import AvenirNextLTProHeavyItWoff from './../../fonts/AvenirNextLTPro-HeavyIt/font.woff';

import AvenirNextLTProMediumWoff2 from './../../fonts/AvenirNextLTPro-Medium/font.woff2';
import AvenirNextLTProMediumWoff from './../../fonts/AvenirNextLTPro-Medium/font.woff';
import AvenirNextLTProMediumItWoff2 from './../../fonts/AvenirNextLTPro-MediumIt/font.woff2';
import AvenirNextLTProMediumItWoff from './../../fonts/AvenirNextLTPro-MediumIt/font.woff';

import AvenirNextLTProRegularWoff2 from './../../fonts/AvenirNextLTPro-Regular/font.woff2';
import AvenirNextLTProRegularWoff from './../../fonts/AvenirNextLTPro-Regular/font.woff';
import AvenirNextLTProItWoff2 from './../../fonts/AvenirNextLTPro-It/font.woff2';
import AvenirNextLTProItWoff from './../../fonts/AvenirNextLTPro-It/font.woff';

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: "AvenirNextLTPro";
    font-weight: normal;
    src:  url('${AvenirNextLTProRegularWoff2}') format("woff2"),
          url('${AvenirNextLTProRegularWoff}') format("woff");
  }
  
  @font-face {
    font-family: "AvenirNextLTPro";
    font-weight: normal;
    font-style: italic;
    src:  url('${AvenirNextLTProItWoff2}') format("woff2"),
          url('${AvenirNextLTProItWoff}') format("woff");
  }
  
  @font-face {
    font-family: "AvenirNextLTPro";
    font-weight: 900;
    src:  url('${AvenirNextLTProHeavyWoff2}') format("woff2"),
          url('${AvenirNextLTProHeavyWoff}') format("woff");
  }
  
  @font-face {
    font-family: "AvenirNextLTPro";
    font-weight: 900;
    font-style: italic;
    src:  url('${AvenirNextLTProHeavyItWoff2}') format("woff2"),
          url('${AvenirNextLTProHeavyItWoff}') format("woff");
  }
  
  @font-face {
    font-family: "AvenirNextLTPro";
    font-weight: 600;
    src:  url('${AvenirNextLTProDemiWoff2}') format("woff2"),
          url('${AvenirNextLTProDemiWoff}') format("woff");
  }
  
  @font-face {
    font-family: "AvenirNextLTPro";
    font-weight: 600;
    font-style: italic;
    src:  url('${AvenirNextLTProDemiItWoff2}') format("woff2"),
          url('${AvenirNextLTProDemiItWoff}') format("woff");
  }
  
  
  @font-face {
    font-family: "AvenirNextLTPro";
    font-weight: 500;
    src:  url('${AvenirNextLTProMediumWoff2}') format("woff2"),
          url('${AvenirNextLTProMediumWoff}') format("woff");
  }
  
  @font-face {
    font-family: "AvenirNextLTPro";
    font-weight: 500;
    font-style: italic;
    src:  url('${AvenirNextLTProMediumItWoff2}') format("woff2"),
          url('${AvenirNextLTProMediumItWoff}') format("woff");
  }

  html {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      // Set rems
      font-size: 50%; // 1rem = 8px
      @media ( ${props => props.theme.breakpoints.sm} ) {
          font-size: 56.25%; // 1rem = 9px
      }
      @media ( ${props => props.theme.breakpoints.md} ) {
          font-size: 62.5%; // 1rem = 10px
      }
      @media ( ${props => props.theme.breakpoints.lg} ) {
          font-size: 68.75%; // 1rem = 11px
      }
      * { box-sizing: border-box; }
  }

  body {
      margin: 0;
      // Use system fonts: https://css-tricks.com/snippets/css/system-font-stack/
      font-family: "AvenirNextLTPro", sans-serif;
      color: ${props => props.theme.colours.lightGreen};
      background-color: ${props => props.theme.colours.white};
  }

  p,
  ol, ul, li,
  code, kbd, pre, samp {
      line-height: 1.3;
      margin-top: 2rem;
      margin-bottom: 2rem;
  }

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
      font-weight: 900;
      line-height: 1.1;
      margin-top: 2rem;
      margin-bottom: 2rem;
      a { 
        text-decoration: none; 
        font-weight: inherit;
      }
      a:hover { text-decoration: none; }
      &.dark {
        color: ${props => props.theme.colours.green};
      }
  }

  h1, .h1 { 
    ${props => props.theme.typeStyles( 4 )}; 
    letter-spacing: ${props => props.theme.typography.letterSpacing};
    @media ( ${props => props.theme.breakpoints.sm} ) {
      ${props => props.theme.typeStyles( 4 )}; 
    }
    @media ( ${props => props.theme.breakpoints.md} ) {
      ${props => props.theme.typeStyles( 5 )}; 
    }
    @media ( ${props => props.theme.breakpoints.lg} ) {
      ${props => props.theme.typeStyles( 5 )}; 
    }
    @media ( ${props => props.theme.breakpoints.xl} ) {
      ${props => props.theme.typeStyles( 6 )}; 
    }
  }
  h2, .h2 { 
    ${props => props.theme.typeStyles( 0 )};
    letter-spacing: ${props => props.theme.typography.letterSpacing}; 
  }
  h3, .h3 { ${props => props.theme.typeStyles( 0 )}; }
  h4, .h4 { ${props => props.theme.typeStyles( 0 )}; }
  h5, .h5 { ${props => props.theme.typeStyles( 0 )}; }
  h6, .h6 { ${props => props.theme.typeStyles( 0 )}; }
  p, ul, ol, blockquote { ${props => props.theme.typeStyles( 0 )}; }

  li {
      ${props => props.theme.typeStyles( 0 )};
      margin-top: 0;
      margin-bottom: 0;
  }
  
  .large {
    ${props => props.theme.typeStyles( 1 )};
  }

  small, .small {  
    display: block;
    margin-top: 1rem;
    margin-bottom: 1rem;
    ${props => props.theme.typeStyles( -2 )};
  }
  
  code, kbd, pre, samp {
      font-family: monospace;
      font-size: 16px;
      white-space: normal;
  }
  
  pre { font-size: 16px; }
  
  ul {
      padding-left: 4rem;
      list-style-type: disc;
  }
  
  ol {
      padding-left: 4rem;
      list-style-type: decimal;
  }
  
  video {
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
  }
  
  em, i { font-style: italic; }
  
  strong, b { font-weight: 600; }
  
  blockquote {
      font-weight: inherit;
      padding: 0 4rem;
      text-align: center;
      p {
        ${props => props.theme.typeStyles( 1 )};
      }
  }
  
  a { 
    font-weight: 600;
    color: inherit; 
    text-decoration: none;
    &:hover { color: ${props => props.theme.colours.green}; }
  }
  
  sup, sub {
      vertical-align: baseline;
      position: relative;
      top: -0.4em;
  }
  
  sub { top: 0.4em; }
  
  label {
      ${props => props.theme.typeStyles( -2 )};
      line-height: 1.2;
      font-weight: 600;
      margin: 1rem 0;
      display: block;
  }
  
  .text-input,
  input[type="text"],
  input[type="password"],
  input[type="date"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="range"],
  input[type="search"],
  input[type="tel"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  textarea {
      display: block;
      font-family:inherit;
      ${props => props.theme.typeStyles( 0 )};
      width: 100%;
      padding: 0.4rem 1rem;
      margin-top: 1rem;
      border: 0;
      border-bottom: 1px ${props => props.theme.colours.lightGreen} solid;
      border-radius: 0;
      line-height: 1.6;
      background-color: transparent;
      -webkit-appearance: none;
      &.small-input {
        ${props => props.theme.typeStyles( -2 )};
        line-height: 1.2;
      }
  }
  
  .file-input,
  input[type="file"],
  .radio-input,
  input[type="radio"],
  .checkbox-input,
  input[type="checkbox"],
  select {
      font-family:inherit;
  }
  
  .button,
  button,
  input[type="submit"],
  input[type="button"],
  input[type="reset"] {
      display: inline-block;
  
      padding: 0;
  
      font-family:inherit;
      ${props => props.theme.typeStyles( 0 )};
      line-height: 2;
      text-decoration: none;
      white-space: nowrap;
      border: none;
      border-bottom: 1px solid;
      border-radius: 2px;
      cursor: pointer;
      font-weight: 900;
  
      color: inherit;
      background-color: transparent;
      
      &:hover {
          text-decoration: none;
      }
      
      &.icon {
        padding: 0;
        border: none;
        border-radius: 0;
        line-height: 0;
        svg {
          width: 3rem;
          height: auto;
          display: block;
        }
      }
      
      &.hidden { 
        visibility: hidden; 
        margin: 0;
        line-height: 0;
        font-size: 0;
        padding: 0;
      }
      &.plainText {
        display: block;
        white-space: normal;
        text-align: left;
        padding: 0;
        margin: 0;
        line-height: inherit;
        border: none;
        border-radius: 0;
        font-weight: inherit;
      }
  }
  
  .fieldset {
    input + p {
      ${props => props.theme.typeStyles( -2 )};
      margin: 0.5rem 0;
      font-style: italic;
    }
  }
  
  form {
    input[type="submit"] { 
      display: block;
      margin: 3rem auto; 
    }
  }
`;

export default GlobalStyle;
