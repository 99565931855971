exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-find-us-js": () => import("./../../../src/pages/find-us.js" /* webpackChunkName: "component---src-pages-find-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ingredients-js": () => import("./../../../src/pages/ingredients.js" /* webpackChunkName: "component---src-pages-ingredients-js" */),
  "component---src-pages-our-pops-js": () => import("./../../../src/pages/our-pops.js" /* webpackChunkName: "component---src-pages-our-pops-js" */),
  "component---src-templates-partner-js": () => import("./../../../src/templates/Partner.js" /* webpackChunkName: "component---src-templates-partner-js" */),
  "component---src-templates-partner-list-page-js": () => import("./../../../src/templates/PartnerListPage.js" /* webpackChunkName: "component---src-templates-partner-list-page-js" */),
  "component---src-templates-story-js": () => import("./../../../src/templates/Story.js" /* webpackChunkName: "component---src-templates-story-js" */),
  "component---src-templates-story-list-page-js": () => import("./../../../src/templates/StoryListPage.js" /* webpackChunkName: "component---src-templates-story-list-page-js" */)
}

