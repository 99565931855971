import React, { Component } from 'react';
import FooterNavigation from './FooterNavigation';
import SignUpForm from './SignUpForm';
import styled from 'styled-components';
import IconFb from '../atoms/iconFb';
import IconInsta from '../atoms/iconInsta';

const Holder = styled.footer`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4rem;
  padding: 0 2rem;
  margin: 8rem 0 4rem 0;
  @media( ${props => props.theme.breakpoints.sm} ) {
    grid-gap: 2rem;
    padding: 0 3rem;
    grid-template-columns: 2fr 2fr 5fr;
  }
  @media( ${props => props.theme.breakpoints.md} ) {
    padding: 0 4rem;
    grid-template-columns: 1fr 2fr 7fr;
  }
`;

const SocialLinks = styled.nav`
  @media( ${props => props.theme.breakpoints.sm} ) {
    justify-self: end;
    align-self: end;
  }
  ul {
    list-style: none;
    padding-left: 0;
    display: flex;
    align-items: center;
    margin: 0;
    li {
      margin: 0 0.5rem;
      &:first-child { margin-left: 0; }
      &:last-child { margin-right: 0; }
      a {
        text-decoration: none;
        svg {
          width: 2.4rem;
          height: auto;
        }
      }
    }
  }
`;

class Footer extends Component {
  render() {
    return (
      <Holder>
        <FooterNavigation/>
        <SignUpForm/>
        <SocialLinks>
          <ul>
            <li>
              <a href="https://www.facebook.com/eatpurepops/" target="_blank" rel="noopener noreferrer" title="Pure Pops Facebook">
                <IconFb/>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/purepops_icepops/" target="_blank" rel="noopener noreferrer" title="Pure Pops Instagram">
                <IconInsta/>
              </a>
            </li>
          </ul>
        </SocialLinks>
      </Holder>
    )
  }
}

export default Footer;
