import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Logo from '../atoms/Logo';
import styled from 'styled-components';
import IconAusMade from '../atoms/iconAusMade';
import Menu from '../organisms/Menu';

const Holder = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${props => props.theme.colours.white};
  z-index: 5;
  padding: 1rem 2rem;
  display: grid;
  grid-template-columns: repeat( 3, 1fr );
  align-items: center;
  grid-auto-flow: dense;
  @media( ${props => props.theme.breakpoints.sm} ) {
    padding: 1rem 3rem;
    grid-template-columns: 1fr 1fr 4fr 1fr 1fr;
  }
  @media( ${props => props.theme.breakpoints.md} ) {
    padding: 1rem 4rem;
    grid-template-columns: 1fr 1fr 6fr 1fr 1fr;
  }
  .logo-holder {
    width: 5rem;
    grid-column: 2/3;
    justify-self: center;
    margin-top: 0.5rem;
    @media( ${props => props.theme.breakpoints.sm} ) {
      grid-column: 3/4;
      width: 8rem;
    }
  }
  svg {
    width: 100%;
    height: auto;
  }
`;

const AusMadeHolder = styled.header`
  width: 6rem;
  justify-self: end;
  grid-column: 3/4;
  @media( ${props => props.theme.breakpoints.sm} ) {
    width: 100%;
    grid-column: 2/3;
    justify-self: start;
    max-width: 9rem;
  }
`;

class Header extends Component {
  render() {
    return (
      <Holder>
        <Menu/>
        <AusMadeHolder>
          <IconAusMade/>
        </AusMadeHolder>
        <Link className="logo-holder" to="/" title="home">
          <Logo/>
        </Link>
      </Holder>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

export default Header
