import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import addToMailchimp from 'gatsby-plugin-mailchimp'

const Holder = styled.div`
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
  > p:last-child {
    margin: 1rem 0 0 0;
    font-weight: 600;
    ${props => props.theme.typeStyles( -3 )};
  }
`;

const Message = styled.div`
`;

const validationSchema = Yup.object().shape( {
  email: Yup.string()
  .email( 'Enter a Valid Email!' )
  .required( 'Email is Required!' ),
} );

function SignUpForm( ) {

  const [ data, setData ] = useState( null );

  if ( data ) {
    return (
      <Message>
        <p dangerouslySetInnerHTML={{ __html: data.msg }}/>
      </Message>
    )
  }

  return (
    <Holder>
      <h4 className="small light">subscribe</h4>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        onSubmit={( values, { setSubmitting } ) => {
          setTimeout( () => {
            setSubmitting( false );

            addToMailchimp( values.email )
            .then( data => {
              setData( data );
            } )

          }, 400 );
        }}
      >
        {( { isSubmitting } ) => (
          <Form>
            <div className='fieldset'>
              <Field className="small-input" aria-label="email" type="email" name="email"/>
              <ErrorMessage name="email" component="p"/>
            </div>
            <button className="small hidden" type="submit" disabled={isSubmitting}>
              Submit
            </button>
          </Form>
        )}
      </Formik>
      <p className="small">For giveaways and loads more sign up for our Pure Pops e-letter!</p>
    </Holder>
  )
}

export default SignUpForm;
