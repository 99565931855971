import React, { useState } from 'react';
import styled from 'styled-components';
import IconHamburger from '../atoms/iconHamburger';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'gatsby';
import IconCross from '../atoms/iconCross';

const Holder = styled.div`
  position: relative;
  button {
    position: relative;
    z-index: 15;
  }
`;

const NavHolder = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: ${props => props.theme.colours.white};
  display: flex;
  align-items: center;
  justify-content: center;
  &.nav-appear,
  &.nav-enter {
    opacity: 0;
    ul {
      opacity: 0;
    }
  }
  &.nav-appear-active, &.nav-appear-done,
  &.nav-enter-active, &.nav-enter-done {
    opacity: 1;
    transition: opacity 250ms;
    ul {
      opacity: 1;
      transition: opacity 250ms 250ms;
    }
  }
  &.nav-exit {
    opacity: 1;
    ul {
      opacity: 1;
    }
  }
  &.nav-exit-active {
    opacity: 0;
    transition: opacity 250ms 250ms;
    ul {
      opacity: 0;
      transition: opacity 250ms;
    }
  }
`;

const NavList = styled.ul`
  list-style: none;
  padding-left: 0;
  text-align: center;
  margin-top: -6rem;
  @media ( ${props => props.theme.breakpoints.sm} ) {
    margin-top: 0;
  }
  li {
    margin: 2rem 0;
    text-transform: lowercase;
    line-height: 1;
    ${props => props.theme.typeStyles( 4 )};
    letter-spacing: ${props => props.theme.typography.letterSpacing};
    @media ( ${props => props.theme.breakpoints.sm} ) {
      ${props => props.theme.typeStyles( 5 )}; 
    }
    a { 
      text-decoration: none;
      font-weight: 900; 
    }
  }
`;

function Menu() {

  const [ show, setShow ] = useState( false );

  return (
    <Holder>
      <button className="icon" aria-label="Toggle the navigation menu" onClick={() => { setShow( !show ) }}>
        {show ? <IconCross/> : <IconHamburger/>}
      </button>
      <CSSTransition
        mountOnEnter
        unmountOnExit
        appear
        in={show}
        timeout={500}
        classNames="nav">
        <NavHolder>
          <NavList onClick={() => { setShow( false ) }}>
            <li><Link to="/our-pops/">Our Pops</Link></li>
            <li><Link to="/about/">About</Link></li>
            <li><Link to="/stories/">Stories</Link></li>
            <li><Link to="/partners/">Our Partners</Link></li>
            <li><Link to="/find-us/">Find Us</Link></li>
            <li><Link to="/contact/">Contact</Link></li>
          </NavList>
        </NavHolder>
      </CSSTransition>
    </Holder>
  )
}

export default Menu;
