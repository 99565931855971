import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Holder =styled.nav`
  ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
    li {
      margin: 0;
      font-weight: 900;
      a {
        text-decoration: none;
      }
    }
  }
`;

class FooterNavigation extends Component {
  render() {
    return (
      <Holder>
        <ul>
          <li className="small"><Link to="/faqs/">FAQs</Link></li>
          <li className="small"><Link to="/find-us/">find us</Link></li>
          <li className="small"><Link to="/ingredients/">ingredients</Link></li>
          <li className="small"><Link to="/contact/">contact</Link></li>
        </ul>
      </Holder>
    )
  }
}

export default FooterNavigation;
