import React from 'react';

function IconCross() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
      <g id="close">
        <line x1="11.1" y1="49" x2="48.46" y2="11.65" fill="none" stroke="#00946e" strokeLinecap="round" strokeWidth="2.38"/>
        <line x1="48.46" y1="49" x2="11.1" y2="11.65" fill="none" stroke="#00946e" strokeLinecap="round" strokeWidth="2.38"/>
      </g>
    </svg>
  )
}

export default IconCross;
