import React from 'react';

function Logo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 115.66 93.18">
      <g id="logo">
        <path d="M15.05,13.69H11.29V9.9h3.76c2,0,3.11.47,3.11,1.81s-1.1,2-3.11,2M14.81.8H.89v30h10.4V22.24h3.52c8,0,12.89-3.58,12.89-10.87S23.2.8,14.81.8" fill="#00946e"/>
        <path d="M43.34,31.38c9.7,0,14.57-4.93,14.57-14.53V.81H47.43V16.3c0,3.33-.9,4.93-4,4.93s-4-1.68-4-5.14V.81H28.89V17.06c0,9.43,4.83,14.32,14.45,14.32" fill="#00946e"/>
        <path d="M73.67,13.82H69.9V9.9h3.77c1.92,0,3.27.42,3.27,1.9s-1.35,2-3.27,2m8.76,5.89A10.85,10.85,0,0,0,87,10.83C87,5,82.59.8,73.5.8H59.83v30H70.07v-9h2l4.71,9H88.61Z" fill="#00946e"/>
        <path d="M103,43.68c-2.46-.51-3.48-.89-3.48-1.6s.9-1,2.37-1a13.71,13.71,0,0,1,8.07,2.82l4.7-7.12a20.7,20.7,0,0,0-12.77-4.08c-7.37,0-12.48,3.45-12.48,10,0,5.09,3.44,8.29,12.52,10.06,2.25.42,3,.8,3,1.56s-1,1.14-2.37,1.14A18.06,18.06,0,0,1,92,51.38l-4.67,7.29a21.31,21.31,0,0,0,14.53,5.18c8,0,13.22-3.71,13.22-10.32,0-5.31-3.11-8-12.11-9.85" fill="#00946e"/>
        <path d="M15.05,46.16H11.29V42.37h3.76c2,0,3.11.46,3.11,1.81s-1.1,2-3.11,2m-.24-12.89H.89v30h10.4V54.71h3.52c8,0,12.89-3.58,12.89-10.86S23.2,33.27,14.81,33.27" fill="#00946e"/>
        <path d="M75.8,46.16H72V42.37H75.8c2,0,3.11.46,3.11,1.81s-1.11,2-3.11,2m-.25-12.89H61.63v30H72V54.71h3.52c8,0,12.89-3.58,12.89-10.86s-4.5-10.58-12.89-10.58" fill="#00946e"/>
        <path d="M44.48,53.91c-3,0-5.07-2.27-5.07-5.64s2-5.6,5.07-5.6,5.08,2.27,5.08,5.6-2.05,5.64-5.08,5.64m.08-21.18c-9.21,0-16.12,6.65-16.12,15.58s6.75,15.54,16,15.54,16.13-6.66,16.13-15.63-6.76-15.49-16-15.49" fill="#00946e"/>
        <polygon points="114.48 9.9 99.54 9.9 99.54 11.71 113 11.71 113 19.8 99.54 19.8 99.54 21.61 114.72 21.61 114.72 30.83 89.39 30.83 89.3 0.8 114.48 0.8 114.48 9.9" fill="#00946e"/>
        <g>
          <path d="M112.49,58.14c-.76-1.33-1.43-.17-1.73.42A48.91,48.91,0,0,0,107.94,63,37.1,37.1,0,0,1,106,66.22c-.35.5-1,1.46-1.27,1.91-1,1.42-1.56,2.24-2.5,3.55,0,0-.26.46-.25.35-.52.75-1.25,1.69-1.84,2.59h0c-.53.77-1.06,1.5-1.66,2.27-.16.21-.29.4-.49.65-1.75,2.68-2.07-.28-1.35-3a18.52,18.52,0,0,1,.52-2.1c.14-.47.63-.88.16-1.19-.64-.48-1.33-.33-1.15.41-.83-1.38-2.16-1-3.16.09a13.68,13.68,0,0,0-3.75,6.66c0,2.2,2.43,2.05,3.27.51A47.8,47.8,0,0,0,95.35,75c-.5,2.09,0,6.24,2.89,4.09a4.1,4.1,0,0,0,.63-.78c.87-1.08,1.5-1.94,2.2-3,.55-.8,1.08-1.53,1.64-2.31s1.36-1.88,1.93-2.72c.12.67-1.11,3.41-1.17,5.11a23.16,23.16,0,0,0-.16,5.65c0,1.9,1.52,2,1,.41.1-.53-.06-.56,0-1.35,0-1,0-1.61.11-2.72a26,26,0,0,1,.54-3.76,53.28,53.28,0,0,1,2-5.92,23.72,23.72,0,0,1,1.53-3.14,4.25,4.25,0,0,0,.32-.54,6.65,6.65,0,0,0,.6-1c.3-.53.39-.6.66-1,.45-.86,1-1.4,1.41-2.12.21-.22.68-1.16,1-1.42C112.45,58.38,112.56,58.3,112.49,58.14ZM97.12,72.61c0,.08,0,0-.1,0S97.09,72.61,97.12,72.61Zm-3.59-1.27,0,.14S93.48,71.32,93.53,71.34ZM90.74,77c.08,0,0,0,0,0S90.83,76.93,90.74,77Zm-.5,1.51.11-.08A.17.17,0,0,1,90.24,78.53Zm4-3.91c-.91,1.82-6.46,8.76-2.47.32.17-.3.44-.65.66-1a10.46,10.46,0,0,1,.64-.89,8.8,8.8,0,0,1,1.57-1.37c.12-.06.52-.21.55-.09a1.8,1.8,0,0,1,.16.86C95.38,73.37,94.62,74,94.23,74.62Zm1.14-2.05s0-.06.06-.08Zm.11,2.26c0-.07,0,0,.07,0S95.49,74.84,95.48,74.83Zm.22,4,0,0S95.75,78.86,95.7,78.86Zm3.64-3s0,0,0,0S99.27,75.9,99.34,75.81Zm4.1,5.87c0-.05,0-.08.07-.08S103.51,81.71,103.44,81.68Zm.12-6.18s-.13,0-.08-.06S103.62,75.48,103.56,75.5Zm2.6-5.81c0,.09,0-.16,0,0A0,0,0,0,1,106.16,69.69Zm2-7,0,.06C108.23,62.8,108.1,62.65,108.18,62.67Zm.21-.25,0,0S108.35,62.47,108.39,62.42Zm1,.66s0,0,0,0Z" fill="#00946e"/>
          <path d="M90.29,71.31c-.24-1.78-3.82,2.76-4.06,3-.61.71-1,1.21-1.34,1.68a2.68,2.68,0,0,0-.38.6c.08,0-.08.22-.08.11,0-1.12,1.15-2.55-.29-2.82-.72.45-.48,1.88-.81,2.71-.21,1-.54,2.39-.8,3.69-.13.46-.17,1-.23,1.2-.38.41.58,1.18.89.86a5.25,5.25,0,0,0,.25-.55l0-.11a.14.14,0,0,1,0,.1c.25-.69.33-1.37.67-2.17h0c0-.1.08-.19.13-.29s.34-.59.23-.49,0,0,0-.06c.34-.47.29-.53.69-1.16a33.24,33.24,0,0,1,4.12-5,7,7,0,0,0,.82-1C90.22,71.52,90.22,71.37,90.29,71.31ZM84.1,79.59c-.12.11,0,0,0,0Zm4-7.16s0-.16,0-.14Zm.56-.69s0-.07.06-.07Zm.45-.39s0,.08,0,.08S89.07,71.43,89.1,71.35Z" fill="#00946e"/>
          <path d="M78.44,77.71c-1.19,1.92-1.78,3-3,4.64,0,0,0,.07-.12.13-1.06,1.4-1.3-1-1-2.41.12-.73.27-1.32.39-2a3.75,3.75,0,0,0,.19-.92A4.68,4.68,0,0,0,75,76.5c.21-.68.58-2.21-.7-1.88-.22.54-.19,1.19-.34,1.64a1.44,1.44,0,0,0,0,.24c-.34,2-1.15,3.94-.65,6.25.17.85,1.84,1.86,2.55.7C77.21,82,78.54,79.6,80,77.22a13.9,13.9,0,0,0,1.66-3c.14-.23.58-.9.34-1.19C80.67,73,79.27,76.85,78.44,77.71ZM76.6,80.77l.06,0S76.61,80.79,76.6,80.77ZM80,75.24h0C80.09,75.15,79.92,75.33,80,75.24Zm.82-1.36h0C80.91,73.84,80.68,74,80.81,73.88ZM81,75.44s0,0,0,0S81,75.51,81,75.44Z" fill="#00946e"/>
          <path d="M53.4,72.76C54,71,55,69,55.66,67.21c.15-.44,2.57-4.65.76-3.1-.32-.1-.51.33-.41.39-.34.27-.44.8-.7,1.29a30.21,30.21,0,0,0-1.64,3.64c-.48,1-1.5,3.72-2,4.92,0,0,0,0,0,0l-.62,1.77c-.12.38-.63,1.82-.85,2.58-.71,2.14-1,3.61-1.58,5.77-.11,1.32-.6.44-1.23-1.26h-.09l.09,0c-1.15-3.13-2.72-9-3.14-9.42-.69-1.42-1.94-6-3-2.5-.38.73-.93,1.9-1.37,3,0,0-.09.16-.08.18-.26.58-.36.93-.65,1.61,0,.1-.11.35-.12.28-.23.57-.46,1.1-.69,1.77h0a4,4,0,0,1-.3.77c-.41,1.18-.86,2.31-1.24,3.56-.06.34-.32.86-.43,1.4s-.55,1.75-.76,2.68c.07.22-.48.84-.3,1.26.43.46,1.29.36,1.2-.25a66.82,66.82,0,0,1,2.54-8.26c.47-1.22,1.12-2.92,1.91-4.74.42-.46,1.13-4,1.74-1.89a42.65,42.65,0,0,1,2,5.37,69.75,69.75,0,0,0,2.74,7.89A7.76,7.76,0,0,1,48,87.39a.78.78,0,0,0,1.24.85,36.71,36.71,0,0,1,1.33-6.7l0-.08s0,0,0,.05C51.34,78.7,52.3,76,53,73.8,53.25,73.28,53.18,73.3,53.4,72.76Zm3.35-8.16s-.08,0,0,0S56.81,64.56,56.75,64.6ZM56.63,64s0,0,0,0S56.72,64,56.63,64Zm-.34.67s.05.15,0,.17S56.31,64.75,56.29,64.69ZM54.84,69c0,.09.13-.12,0,0S54.79,69.07,54.84,69ZM53.1,73.26s0,0,0,0S53.18,73.18,53.1,73.26Zm-12.18,1.3.07-.08S41,74.55,40.92,74.56Zm3.58,2.71c-.1,0,0-.14,0,0S44.55,77.24,44.5,77.27Zm.59-1.22a.14.14,0,0,1,.07.08ZM48,84.72l0-.09C48,84.52,48,84.69,48,84.72Zm2.29-2.55,0,0C50.25,82.07,50.29,82.15,50.27,82.17Zm1-6.37a.08.08,0,0,1,0,.08C51.26,75.85,51.24,75.79,51.32,75.8Zm.08,2.75a.09.09,0,0,1-.08,0S51.48,78.38,51.4,78.55ZM51.84,75v0S51.85,75.05,51.84,75Z" fill="#00946e"/>
          <path d="M77.16,71.66c.39-.16,1.55-.32,2.29-.52s1.85-.37,2.79-.59,1.74-.33,2.53-.47c1.21-.22,1.49-.21,2.57-.47,1.58-.13,4-.68,5.89-.91-.36-.08.61.07.86-.17-.08.08.39,0,.35,0,1.66-.24,2.8-.32,4.28-.53a13.05,13.05,0,0,0,1.79-.09c.52.1,1.24,0,1-.46-1.49-1.14-4.58-.13-6.43,0-3.48.4-5.65.84-9.27,1.42-2,.4-6.34,1.12-9,1.84h0a11.41,11.41,0,0,0-1.51.34c-.62.11-1.63.35-2.58.6l0,0s0,0,0,0l-.36.09c-.55,0-1.36.67-1,0,.57-1.39,1.15-2.94,1.77-4.22.26-.49.29-.62.53-1.12s1.12-1.15.49-1.71c-.06,0-.11-.07,0,0-1.5-1.28-2,3-2.88,4.2-1.67,3.79-1.23,3.67-4.83,4.44a19.3,19.3,0,0,1-3.8.62c-1.36-.48-1.7.56-.45.89,7.41,0,8.46-4.24,3,4.91-.35.58-1,1.48-1.38,2.15-.6.94-.94,1.41-1.6,2.29a7.82,7.82,0,0,1-1,1c-2.28,1.24-1.51-2.47-1.18-3.89a21.2,21.2,0,0,1,1.45-4.75c-.21-.74-1.6-.7-1.37.16.25-.15-.1.08-.11.21-.17.57-.34-.4-.86-.48-2.48-.68-4.67,3.14-5.43,4.87-.74,1.37-.29,4.19,1.84,3.48,1.39-.83,2-2.19,2.81-3.08.33-.42.69-1.21.52-.38.09-.07-.16.35-.13.61-1.07,3.12,1.09,6.25,4,3.24a25.55,25.55,0,0,0,1.8-2.46s.11-.19,0-.07c1.59-2.46,2.76-4.36,4-6.48,0,.85-1.09,4.27-.9,5.66,0,1-.48,3.73.92,4.1.85.1.15-1.32.22-1.51a.21.21,0,0,0,0-.14,16.38,16.38,0,0,1,.09-3.9c0-.08,0-.08,0-.19A31.5,31.5,0,0,1,70,75.9c.85-3.49.87-2.73,3.88-3.5C75,72.12,76.28,71.83,77.16,71.66Zm15.63-3s0,0,0,0S92.78,68.7,92.79,68.69ZM91.15,69c.06,0,0,0,0,0S91.13,69,91.15,69ZM91,69l0-.08S91,69,91,69Zm0,0,0,0S91,68.93,91,69Zm-7.39.35s0,.13-.09.1S83.58,69.31,83.62,69.31Zm-4,1.66a.1.1,0,0,1-.05.1ZM64.44,73.74s0,0,0,0S64.34,73.69,64.44,73.74Zm-5.62,2.57c0,.06,0,0-.07,0S58.8,76.33,58.82,76.31Zm-1.28,4.48c-.12.16-.76,1-.88,1.17a12.9,12.9,0,0,1-1,1.21c-.46.63-1.27.93-1.2.14a6,6,0,0,1,1.29-3.68,8.86,8.86,0,0,1,2.56-2.5c1-.44.51.62.79,1.28C58.61,79.15,58,80.26,57.54,80.79Zm3.25,4.65s0-.16,0-.14Zm12.86-13s-.2.11-.07,0C73.62,72.47,73.68,72.35,73.65,72.43Z" fill="#00946e"/>
          <path d="M32.32,70.13c-.15.38-.48.71-.72,1.15a6.6,6.6,0,0,0-.56.8s-.07,0-.08.08c-.5.67-.79,1.07-1.22,1.71.08,0-.13.19-.06.06-1.49,2.16-2.53,3.64-3.77,5.47-1,1.43-2,2.94-3.1,4.57-.55.75-1,1.54-1.66,2.4-2.28,3,2.91-11.39,3.43-11.89.07-.2.22-.37.23-.51.61-.51-.58-1.31-1-.73-.22.39-.46,1-.89,1.13-1.25.64-3,1.7-4,2.2-5.18,3.05-2.93,2.27-3.17-4.11-.23-.92.65-2.59-.3-3s-1.24,1.08-1.74,1.6c-1.33,2-2.95,4.5-4.29,6.5-1.45,2.29-3.37,5-4.64,7.35.12,0-.11.09-.1.14-.58,1-.87,1.53-1.2,2.09s-.66,1.25-1,1.84c-.47.75-2.58,4.81-.45,3.14A81.33,81.33,0,0,1,6,84.88c.09-.21.12-.19.11-.24.64-.29,1.71-.8,2.47-1.22,1.11-.51,2.77-1.44,3.9-2,.51-.16,2-1.41,2.07-.89,0,.85,0,2,0,2.65a31.53,31.53,0,0,0,1.62,9.08c1.47.78.34-2.31.14-3.13,0,0,0,0,0,0a16.6,16.6,0,0,1-.42-2.1c.07,0-.11-.53,0-.38,0-.33-.12-.82-.08-.82s0-.28,0-.5a42,42,0,0,1-.13-4.31,1.59,1.59,0,0,1,1.25-2.13c.81-.19,6.82-4.48,5.78-2.83,0,0-.28.82-.32.9-.81,2.33-3.88,9.53-2.76,11.77.06.06.21,0,.22.06,1.34.47,2.18-2.41,2.79-2.78.22-.21.38-.71.42-.63.3-.39.5-.72.68-1,.81-1.13,1.52-2.3,2.41-3.55.33-.45.81-1.26,1.28-1.89s1.55-2.12,2-2.88c.35-.55-.19.68-.29,1-.33,1-.76,2.23-.95,2.82-.49,2.17-3,9.35-.9,10.48.94.19,0-1.41.2-2.07,0-.86.28-2.21.39-3,1.1-5,2.75-10.4,5.57-14.79a9.45,9.45,0,0,0,.54-1c.05-.11.75-.55.51-1C33.59,67,32.93,69.4,32.32,70.13ZM14.63,76c-.05.64-.05,2.21-.16,3.09-1.16.76-2.7,1.43-4,2.22-1.1.43-2.59,1.44-3.31,1.62,1.12-1.59,2.21-3.51,3.1-4.78.13-.18.24-.24.3-.43s.28-.42.43-.67c1.15-1.75,2.2-3.24,3.54-5.36C15.13,71.91,14.53,74.65,14.63,76Zm2,16.29,0,0S16.64,92.29,16.64,92.31ZM22.3,79.9s0,.1,0,.08S22.3,79.92,22.3,79.9Zm1.45,4.49s0,0-.05,0S23.78,84.34,23.75,84.39Zm2.77,2.84a.15.15,0,0,1,0-.07S26.52,87.22,26.52,87.23Z" fill="#00946e"/>
        </g>
      </g>
    </svg>
  )
}

export default Logo;
