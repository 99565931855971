import React from 'react';

function IconHamburger() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
      <g id="hamburger">
        <g>
          <line x1="3.94" y1="11.68" x2="56.06" y2="11.68" fill="none" stroke="#00946e" strokeLinecap="round" strokeWidth="2.21"/>
          <line x1="3.94" y1="30" x2="56.06" y2="30" fill="none" stroke="#00946e" strokeLinecap="round" strokeWidth="2.21"/>
          <line x1="3.94" y1="48.32" x2="56.06" y2="48.32" fill="none" stroke="#00946e" strokeLinecap="round" strokeWidth="2.21"/>
        </g>
      </g>
    </svg>

  )
}

export default IconHamburger;
