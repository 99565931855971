import React from 'react';

function IconFb() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <g id="social">
        <path d="M15.15,29.64c0-2.7,0-5.41,0-8.11,0-1-.24-1.44-1.35-1.43-2.64,0-2.64,0-2.64-2.66V16.9c0-1-.28-2.27.22-2.88s1.79-.38,2.74-.43c.77-.05,1-.4,1-1.11,0-1.4,0-2.8,0-4.19.12-3.94,2.44-7,6.66-7.21C23.76,1,25.7,1,27.63.92c.84,0,1.28.25,1.25,1.16,0,1.39,0,2.79,0,4.19,0,.8-.34,1.12-1.14,1.13a15.67,15.67,0,0,0-3.22.24,2,2,0,0,0-1.36,1.22,18,18,0,0,0-.17,4c0,.24.69.63,1.09.66,1.21.09,2.43.06,3.65,0,.81,0,1.16.27,1.06,1.11-.18,1.52-.28,3.05-.54,4.55a1.43,1.43,0,0,1-1,.85,16.35,16.35,0,0,1-3,0c-1.05-.07-1.41.31-1.4,1.39.05,5.37,0,10.73.05,16.1,0,1.26-.32,1.81-1.67,1.72a43.9,43.9,0,0,0-4.73,0c-1.08,0-1.4-.39-1.38-1.43.06-2.75,0-5.5,0-8.25" fill="#93b4ab"/>
      </g>
    </svg>
  )
}

export default IconFb;
