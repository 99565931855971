import React from 'react';

function IconInsta() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <g id="social">
        <path d="M30.86,12.79c3.37,0,3.71.71,3.67-3.66,0-3.87.21-3.62-3.66-3.66-4.32,0-3.62.25-3.66,3.66-.05,3.81-.16,3.71,3.65,3.66m-3.08,7.14c.25-4-3.37-7.55-7.71-7.63a7.62,7.62,0,1,0,0,15.23c4.68-.11,8-3.85,7.73-7.6M8.52,17.19c-.6-.24-1.14-.49-1.7-.65-.94-.28-1.37.06-1.37,1.09q0,7.64,0,15.26c0,1.12.46,1.64,1.6,1.64H32.94c1,0,1.57-.41,1.57-1.51q0-7.77,0-15.55c0-.9-.47-1.23-1.22-.93s-1.74,0-1.64,1.42a17.52,17.52,0,0,1-.19,5.27c-1.25,5.15-4.94,7.88-9.94,8.6A11.3,11.3,0,0,1,10.32,27a10.6,10.6,0,0,1-1.8-9.83M39.22,20c0,4.79,0,9.58,0,14.37a4.5,4.5,0,0,1-4.84,4.84q-14.37,0-28.75,0A4.47,4.47,0,0,1,.79,34.37Q.76,20,.79,5.63A4.48,4.48,0,0,1,5.63.79Q20,.76,34.37.79a4.49,4.49,0,0,1,4.84,4.84c0,4.79,0,9.58,0,14.37" fill="#93b4ab"/>
      </g>
    </svg>
  )
}

export default IconInsta;
